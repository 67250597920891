/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import { grey } from '@mui/material/colors';

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

export default function data() {
  const [user, setUser] = useState([]);
  const navigate = useNavigate(); // Utilize useNavigate for redirection

  // console.log(user[2].patient_detail.firstname);
  const handleDelete = (id) => {
    const result = window.confirm('Do you want to continue?');
    if (result) {

      const booking_id = id;
      fetch(process.env.REACT_APP_API_URL + `/booking/deletebooking/${booking_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => response.json())
        .then(data => {
          alert(data.message);
        })

    }

  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('jwt_token');
    if (storedUser && storedToken) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const user_id = parsedUser.id;
        fetch(process.env.REACT_APP_API_URL + `/booking/getallbooking`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${storedToken}`
          }
        })
          .then(response => response.json())
          .then(data => {
            data.sort((booking1, booking2) => booking1.id - booking2.id);
            console.log(data);
            setUser(data);
          })

      } catch (error) {
        console.error('Error parsing stored user data:', error);
      }
    } else {
      // No user or token found, redirect to login page
      navigate('/authentication/sign-in'); // Assuming your login page path is '/login'
    }

  

  }, []);

  const sendReportReminder = async(item) => {
    console.log('Report Sent');
    // call the /booking/completebooking/ api to send report email which takes booking_id as parameter and status as 'completed'
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/booking/completebooking/${item.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: 'completed'
      })
      });

      if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Add at the top of the file along with other imports

      showAlert('Scan Report sent successfully!', 'success');

    } catch (error) {
      console.error('Error sending scan report:', error);
      showAlert('Failed to send scan report!', 'error');
    }

  }

  const sendReminder = async(item) => {
    console.log('Reminder Sent');
    // call the /payment/sendmail api to send reminder email which takes email and paymentLink as parameter
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + `/payment/sendmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: item.patient_detail.email,
        paymentLink: 'http://skannr.com/Payment?booking_id=' + item.id
      })
      });

      if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // Add at the top of the file along with other imports

      showAlert('Payment reminder sent successfully!', 'success');

    } catch (error) {
      console.error('Error sending payment reminder:', error);
      showAlert('Failed to send payment reminder!', 'error');
    }

  }


  return {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Patient ", accessor: "Patient", align: "left" },
      { Header: "Doctor", accessor: "Doctor", align: "center" },
      { Header: "scan Type", accessor: "Scan_Type", align: "center" },
      { Header: "Center", accessor: "Center", align: "center" },
      { Header: "Status", accessor: "Status", align: "center" },
      { Header: "Payment", accessor: "Payment", align: "center" },
      { Header: "Date", accessor: "Date", align: "center" },

    ],


    rows: user.map((item) => (
      {
        id: (

          <MDTypography variant="caption" fontWeight="medium">
            <Link to={`/booking/bookingdetails/${item.id}`}>
              {item.id}
            </Link>
          </MDTypography>
        ),

        Patient: (
          item.patient_detail.firstname == '' ? ('No Patient') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.patient_detail.firstname + " " + item.patient_detail.lastname}
            </MDTypography>
          )
        ),

        Doctor: (
          item.doctor == null ? ('No Doctor Assign') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {(item.doctor.firstname + " " + item.doctor.lastname)}
            </MDTypography>
          )
        ),
        Scan_Type: (
          item.scan_type == null ? ('No Scan Type') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.scan_type.name ? (item.scan_type.name) : ('no')}
            </MDTypography>)
        ),
        Center: (
          item.center == null ? ('No Center') : (
            <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
              {item.center.name ? (item.center.name) : ('no')}
            </MDTypography>
          )
        ),
        Status: (
          item.status === 'pending' ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status} color={grey[500]} variant="gradient" size="sm" />
            </MDBox>
          ) : item.status === 'cancelled' ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.status} color="error" variant="gradient" size="sm" />
            </MDBox>
          )
            : item.status === 'completed' ? (
              <MDBox ml={-1}>
                <Tooltip title="Click to send scan Report!" arrow placement="top"> 
                <MDTypography component="button" onClick={() => sendReportReminder(item)} variant="caption" fontWeight="medium">
                <MDBadge badgeContent={item.status} color="success" variant="gradient" size="sm" />
                </MDTypography>
                </Tooltip>
              </MDBox>
            ):
            (
              <MDBox ml={-1}>
                <MDBadge badgeContent={item.status} color="success" variant="gradient" size="sm" />
              </MDBox>
            )
        ),
        Payment: (
          item.payment && item.payment.status === 'paid' ? (
            <MDBox ml={-1}>
              <MDBadge badgeContent={item.payment.status} color="success" variant="gradient" size="sm" />
            </MDBox>
          )
            : (
              <MDBox ml={-1}>
                <Tooltip title="Click to send payment reminder!" arrow placement="top"> 
                <MDTypography component="button" onClick={() => sendReminder(item)} variant="caption" fontWeight="medium">
                  <MDBadge badgeContent={"unpaid"} color="error" variant="gradient" size="sm" />
                </MDTypography>
                </Tooltip>
              </MDBox>
            )
        ),
        Date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {(item.appointment_date ? item.appointment_date.split(" ")[0] : "") + " AT " + (item.appointment_time ? item.appointment_time : "")}
          </MDTypography>
        ),
        time: item.appointment_time,
        date: item.appointment_date
      })),
  };
}
